<template>
    <v-container class="panel-wrapper mt-4" style="background-color: #fff">
        <v-layout column>
            <v-flex>
                <v-layout row>
                    <v-flex grow>
                        <v-subheader>
                            <h2>My tickets</h2>
                        </v-subheader>
                    </v-flex>
                    <v-flex shrink>
                        <v-btn class="ml-3 text-none" color="primary" @click="$refs.newticket.open()">New Ticket</v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex>
                <v-list three-line>
                        <v-list-tile v-if="loading" class="my-3">
                        <v-list-tile-content>
                            <Spin></Spin>
                        </v-list-tile-content>
                    </v-list-tile>
                    <template v-if="!loading">
                        <template v-if="ticketList.length === 0">
                            <v-list-tile  class="my-3">
                                <v-list-tile-content>
                                    <Empty></Empty>
                                </v-list-tile-content>
                            </v-list-tile>
                        </template>
                        <template v-if="ticketList.length !== 0">
                            <v-list-tile  v-for="(item,i) in ticketList" :to="`/ticket/${item.id}`" v-bind:key="i">
                                <v-list-tile-content>
                                    <v-list-tile-title class="mb-2">
                                        <span style="font-weight: 600;color: #3F51B5;">{{item.subject}}</span>
                                    </v-list-tile-title>
                                    <v-list-tile-sub-title class="">
                                        <span style="display:inline-block;width: 400px">Update on {{formatDate(item.received_date,'MM/DD/YYYY HH:mm ' )}}</span>
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                                <v-list-tile-action>
                                    <i v-if="item.status==='Closed'" :style="{color: $vuetify.theme.accent}"
                                       class="fa fa-envelope ticket-icon" aria-hidden="true"></i>
                                    <i v-else-if="item.status==='Waiting'"
                                       :style="{color: $vuetify.theme.secondary}" style="color: yellow"
                                       class="fa fa-envelope-open ticket-icon" aria-hidden="true"></i>
                                    <i v-else :style="{color: $vuetify.theme.success}"
                                       class="fa fa-envelope ticket-icon" aria-hidden="true"></i>
                                </v-list-tile-action>
                            </v-list-tile>
                        </template>
                    </template>
                </v-list>
            </v-flex>
        </v-layout>
        <NewTicket ref="newticket" @confirm="createTicket"></NewTicket>
    </v-container>
</template>

<script>
    import NewTicket from './_source/NewTicket.vue'
    import Spin from '@/components/Spin'
    import Empty from '@/components/Empty'
    import {formatDate} from '@/module/utils/date'
    import {mapActions,mapState} from 'vuex'

    export default {
        name: "TicketList",
        components: {
            NewTicket,Empty,
            Spin
        },
        data() {
            return {
                loading: false,
                ticketList: []
            }
        },
        computed:{
            ...mapState('client', ['clientInfo']),
        },
        methods: {
            formatDate,
            getTicketsList(flag) {
                if (flag) {
                    this.loading = true
                }
                this.getTickets(true).then((res) => {
                    if (flag) {
                        this.loading = false
                    }
                    this.ticketList = res
                }).catch(() => {
                    if (flag) {
                        this.loading = false
                    }
                    this.$message.error('Oops, it failed to list the tickets. You can reach us at support@cloudclusters.io for this issue.')
                })
            },
            createTicket(formData) {
                return this.sendTicket(formData).then((res) => {
                    this.$message.success(`Your ticket ID ${res.number} has been submitted successfully. Our support staff will get back to you within 1-2 hours. Thanks for your patience.`)
                    this.getTicketsList()
                    return Promise.resolve(res)
                }).catch((e) => {
                    this.$message.error('Oops, you failed to create a new ticket. You can reach us at support@cloudclusters.io for this issue.')
                    return Promise.reject(e)
                })
            },
            ...mapActions('ticket', ['sendTicket', 'getTickets']),
        },
        mounted() {
            this.getTicketsList(true)
            const query = this.$route.query
            if (query.status) {
                const dataMap = {
                    'Deploy Failed':{
                        subject: `Redeploy ${query.name}`,
                        department:1,
                        content:`Hello support,<br>My ${query.name} was failed to deploy.Could you please check?<br>${this.clientInfo.first_name} ${this.clientInfo.last_name}`
                    },
                    'Suspended': {
                        subject: `Redeploy ${query.name}`,
                        department:1,
                        content:`Hello support,<br>My ${query.name} was suspended. Could you please check the reason?<br>${this.clientInfo.first_name} ${this.clientInfo.last_name}`
                    },
                    'Cancelled': {
                        subject: `Request to Restore the Cancelled ${query.name}`,
                        department:3,
                        content:`Hello support,<br>I want to restore application ${query.name}.Could you please help? <br>${this.clientInfo.first_name} ${this.clientInfo.last_name}`
                    },
                    'FtpFailed': {
                        subject: `Start FTPS Server for ${query.name}`,
                        department:1,
                        content:`Hello support,<br>It failed to start FTPS service for ${query.name}.Could you please check? <br>${this.clientInfo.first_name} ${this.clientInfo.last_name}`
                    },
                }
                this.$refs.newticket.open(dataMap[query.status])
            }
        },
        created() {
            this.$SDK.track({
                pageName : 'My_tickets'
            })
        }
    }
</script>

<style lang="scss">
    .panel-wrapper {
        .ticket-icon {
            font-size: 20px;
        }
    }
</style>
