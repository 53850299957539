var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper mt-4",
      staticStyle: { "background-color": "#fff" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { grow: "" } },
                    [_c("v-subheader", [_c("h2", [_vm._v("My tickets")])])],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-3 text-none",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$refs.newticket.open()
                            }
                          }
                        },
                        [_vm._v("New Ticket")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            [
              _c(
                "v-list",
                { attrs: { "three-line": "" } },
                [
                  _vm.loading
                    ? _c(
                        "v-list-tile",
                        { staticClass: "my-3" },
                        [_c("v-list-tile-content", [_c("Spin")], 1)],
                        1
                      )
                    : _vm._e(),
                  !_vm.loading
                    ? [
                        _vm.ticketList.length === 0
                          ? [
                              _c(
                                "v-list-tile",
                                { staticClass: "my-3" },
                                [_c("v-list-tile-content", [_c("Empty")], 1)],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.ticketList.length !== 0
                          ? _vm._l(_vm.ticketList, function(item, i) {
                              return _c(
                                "v-list-tile",
                                { key: i, attrs: { to: "/ticket/" + item.id } },
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c(
                                        "v-list-tile-title",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "600",
                                                color: "#3F51B5"
                                              }
                                            },
                                            [_vm._v(_vm._s(item.subject))]
                                          )
                                        ]
                                      ),
                                      _c("v-list-tile-sub-title", {}, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "400px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Update on " +
                                                _vm._s(
                                                  _vm.formatDate(
                                                    item.received_date,
                                                    "MM/DD/YYYY HH:mm "
                                                  )
                                                )
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-list-tile-action", [
                                    item.status === "Closed"
                                      ? _c("i", {
                                          staticClass:
                                            "fa fa-envelope ticket-icon",
                                          style: {
                                            color: _vm.$vuetify.theme.accent
                                          },
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      : item.status === "Waiting"
                                      ? _c("i", {
                                          staticClass:
                                            "fa fa-envelope-open ticket-icon",
                                          staticStyle: { color: "yellow" },
                                          style: {
                                            color: _vm.$vuetify.theme.secondary
                                          },
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "fa fa-envelope ticket-icon",
                                          style: {
                                            color: _vm.$vuetify.theme.success
                                          },
                                          attrs: { "aria-hidden": "true" }
                                        })
                                  ])
                                ],
                                1
                              )
                            })
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("NewTicket", { ref: "newticket", on: { confirm: _vm.createTicket } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }